<template>
    <div class="page-body file-maanger-modal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app section-body">
                <div
                    class="modal-content-header modal-list-third-party-app d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
                >
                    <div class="w-30 d-flex justify-content-between align-items-center">
                        <span v-if="isEditable" class="label-app-list-header">Edit File</span>
                        <span v-else class="label-app-list-header">Add New File</span>
                    </div>
                </div>

                <div v-if="!loading">
                    <ValidationObserver ref="form">
                        <form style="box-shadow: none" class="p-0">
                            <div class="m-4 d-flex flex-column w-80" style="gap: 20px">
                                <div class="d-flex align-items-center">
                                    <label class="me-3 d-flex add-new-app-label justify-content-end w-30" for="url">
                                        Type*
                                    </label>
                                    <Multiselect
                                        class="w-70"
                                        label="name"
                                        track-by="type"
                                        :options="typeList"
                                        :searchable="true"
                                        :placeholder="'Select Type'"
                                        :show-labels="false"
                                        :allow-empty="false"
                                        v-model="typeInfo"
                                        @input="handleTypeChange"
                                        required
                                        :disabled="isEditable"
                                    >
                                    </Multiselect>
                                </div>
                                <span v-if="typeErr" class="d-flex justify-content-center" style="color: red">{{
                                    typeErr
                                }}</span>

                                <Input
                                    label="Title"
                                    labelClass="w-45 me-3 d-flex justify-content-end add-new-app-label"
                                    type="text"
                                    class="field-container d-flex align-items-center"
                                    name="title"
                                    placeholder="Enter Title"
                                    v-model="fileDetails.name"
                                    required
                                />
                            </div>
                            <div class="d-flex align-items-center">
                                <label class="me-3 d-flex add-new-app-label justify-content-end" style="width: 27%"
                                    >Choose File*</label
                                >
                                <div class="d-flex align-items-center">
                                    <button
                                        type="button"
                                        class="no-bg text-button btn-add-new-app"
                                        style="margin-right: 3px; width: 23rem"
                                        @click="chooseFile"
                                        :style="{ cursor: fileDetails.type == '' ? 'not-allowed' : 'pointer' }"
                                    >
                                        Choose file
                                    </button>
                                    <input
                                        id="fileid"
                                        type="file"
                                        name="upload"
                                        ref="upload"
                                        size="100000"
                                        hidden
                                        required
                                        @change="uploadFile"
                                        :disabled="fileDetails.type == ''"
                                        :accept="acceptanceCreteria"
                                    />
                                </div>
                            </div>
                            <span v-if="fileErr" class="d-flex justify-content-center" style="color: red">{{
                                fileErr
                            }}</span>

                            <div class="section-footer add-new-app-form my-4">
                                <div class="d-flex justify-content-center" style="bottom: 10%">
                                    <button type="button" class="primary btn-save" @click="saveRecord">Save</button>
                                    <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>

            <div
                v-if="loading"
                class="d-flex flex-column justify-content-center align-items-center loader-main"
                style="min-height: 12rem"
            >
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { fileManager } from '../../util/apiRequests';
    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'fileManagerModal',
        components: { NoButtonModal, ValidationObserver },
        data() {
            return {
                loading: true,
                isEditable: false,
                typeInfo: '',
                fileErr: '',
                typeErr: '',
                fileDetails: {
                    name: '',
                    type: '',
                    file: '',
                },
                typeList: [
                    { type: 'audio', name: 'Audio' },
                    { type: 'pdf', name: 'PDF' },
                    { type: 'video', name: 'Video' },
                ],
                isFileAdded: false,
            };
        },
        props: {
            documentId: {
                type: Number,
            },
        },
        computed: {
            acceptanceCreteria() {
                if (this.typeInfo?.type === 'audio') {
                    return '.mp3';
                }
                if (this.typeInfo?.type === 'pdf') {
                    return '.pdf';
                }
                if (this.typeInfo?.type === 'video') {
                    return '.mp4, .webm';
                }
                return '';
            },
        },
        methods: {
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            uploadFile(event) {
                const file = event.target.files[0];
                const fileName = file.name;
                const fileSize = file.size;
                let fileNameSplit = fileName.split('.');
                let fileExtension = fileNameSplit[1];

                if (this.fileDetails.type === '') {
                    this.$toasted.error('Please select file type first');
                }

                if (this.fileDetails.type === 'audio' && fileExtension !== 'mp3') {
                    this.$toasted.error('Only mp3 files are allowed');
                    this.$refs.upload.value = '';
                    return;
                }

                if (this.fileDetails.type === 'pdf' && fileExtension !== 'pdf') {
                    this.$toasted.error('Only pdf files are allowed');
                    this.$refs.upload.value = '';
                    return;
                }

                if (this.fileDetails.type === 'video' && !['mp4', 'webm'].includes(fileExtension)) {
                    this.$toasted.error('Only video file formats(.mp4 & .webm) are allowed');
                    this.$refs.upload.value = '';
                    return;
                }

                if (fileSize / 1024 / 1024 > 20) {
                    this.$toasted.error('File size should be less than 20MB');
                    this.$refs.upload.value = '';
                    return;
                }

                this.fileErr = '';
                this.isFileAdded = true;
                this.fileDetails.file = file;
                this.$toasted.success('File uploaded successfully');
            },
            async getDocumentDetails() {
                const endpoint = fileManager.getDocumentDetails(this.documentId);
                const response = await this.$api.get(endpoint);
                const data = response.data?.info;
                if (data) {
                    this.fileDetails = data;
                    this.typeInfo = this.typeList.find((type) => type.type === data.type);
                }
            },
            async saveRecord() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    const { type, name, file, file_id } = this.fileDetails;

                    if (!isFormValid) {
                        // this.$toasted.error('Please fill all required fields');
                        return;
                    }

                    if (!type) {
                        this.typeErr = 'Please select file type';
                        return;
                    }

                    if (!file && !file_id) {
                        this.fileErr = 'Please upload a file';
                        return;
                    }
                    let response;
                    this.loading = true;
                    let endpoint = fileManager.checkNameExists();
                    response = await this.$api.post(endpoint, {
                        fileName: this.fileDetails.name,
                        id: this.$route?.query?.id,
                    });
                    if (!response.data.success) {
                        this.loading = false;
                        return this.$toasted.error(response?.data?.error);
                    }
                    const formData = new FormData();
                    formData.append('name', name);
                    formData.append('type', type);

                    if (this.isEditable) {
                        if (this.isFileAdded) {
                            formData.append('file', file);
                        } else {
                            formData.append('file_id', file_id);
                        }
                        const endpoint = fileManager.updateDocument(this.documentId);
                        response = await this.$api.put(endpoint, formData);
                    } else {
                        formData.append('file', this.fileDetails.file);
                        const endpoint = fileManager.createDocument();
                        response = await this.$api.post(endpoint, formData);
                    }

                    if (response.data.success) {
                        this.$emit('cancel');
                        this.$toasted.success(`File ${this.isEditable ? 'update' : 'added'} successfully`);
                    }

                    this.loading = false;
                } catch (err) {
                    this.$toasted.error(`Failed to ${this.isEditable ? 'update' : 'add'} file`);
                }
            },
            handleTypeChange(val) {
                this.typeErr = '';
                this.fileDetails.type = val.type;
            },
        },
        async created() {
            if (typeof this.documentId === 'number') {
                this.isEditable = true;
                await this.getDocumentDetails();
            }
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        font-size: 18px;
        color: #2e3343;
        margin: 0 0 20px;
        background-color: #f1f7ff;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .file-maanger-modal .modal__content {
        padding: 0;
        border-radius: 10px;
    }
</style>
